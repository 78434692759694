<template lang="html">
  <div>
    <vs-row>
      <vs-col
        id="content"
        class="vs-con-loading__container"
        type="flex"
        vs-align="center"
        vs-w="7.5"
      >
        <vx-card v-if="data_local" :title="$t('common.registerData')">
          <div class="vx-row">
            <div class="vx-col sm:w-full md:w-1/2">
              <vs-input
                class="w-full mt-4"
                label="Id"
                v-model="data_local.id"
                name="id"
                v-if="data_local.id"
                disabled
              />
              <vs-input
                class="w-full mt-4"
                label="Cod"
                v-model="data_local.code"
                name="code"
                v-if="data_local.code"
                disabled
              />

              <div class="mt-4">
                <label class="vs-input--label">{{ $t("machine.model") }}</label>
                <v-select
                  v-model="data_local.type"
                  :clearable="false"
                  :options="modelOptions"
                  v-validate="'required'"
                  name="model"
                  :searchable="true"
                  :reduce="x => x.value"
                  label="label"
                  :data-vv-as="$t('machine.model')"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('model')"
                  >{{ errors.first("model") }}</span
                >
              </div>

              <vs-input
                class="w-full mt-4"
                :label="$t('machine.serial_number')"
                v-model="data_local.serial_number"
                v-validate="'required'"
                name="serial_number"
                :data-vv-as="$t('machine.serial_number')"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('serial_number')"
                >{{ errors.first("serial_number") }}</span
              >

              <vs-input
                class="w-full mt-4"
                :label="$t('common.description')"
                v-model="data_local.description"
                v-validate="'required'"
                name="description"
                :data-vv-as="$t('common.description')"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('description')"
                >{{ errors.first("description") }}</span
              >

              <address-component :address="data_local" v-on:address_changed="addressChanged"></address-component>
            </div>

            <div class="vx-col sm:w-full md:w-1/2">
              <div class="mt-4">
                <label class="vs-input--label">{{
                  $t("machine.company")
                }}</label>

                <v-select
                  v-model="data_local.current_company"
                  :clearable="false"
                  :options="companyOptions"
                  v-validate="'required'"
                  name="current_company"
                  :searchable="true"
                  :reduce="x => x.id"
                  label="name"
                  :data-vv-as="$t('machine.company')"
                  v-if="$store.state.AppActiveUser.type > 0"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>
                <vs-input
                  class="w-full"
                  v-model="data_local.company"
                  disabled
                  v-else
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('current_company')"
                  >{{ errors.first("current_company") }}</span
                >
              </div>

              <div class="mt-4">
                <label class="vs-input--label">{{
                  $t("machine.activationDate")
                }}</label>
                <datepicker
                  :placeholder="$t('machine.activationDate')"
                  v-model="data_local.activation_date"
                  :language="languages[currentLanguage]"
                  format="dd/MM/yyyy"
                ></datepicker>
              </div>

              <div class="mt-4" v-if="$store.state.AppActiveUser.type > 0">
                <label class="vs-input--label">{{
                  $t("company.contracts")
                }}</label>
                <v-select
                  v-model="data_local.contracts"
                  multiple
                  :closeOnSelect="false"
                  :options="contractOptions"
                  :reduce="x => x.id"
                  label="contract_code"
                  v-if="$store.state.AppActiveUser.type === 2"
                >
                  <span slot="no-options">{{ $t("navbar.noResults") }}</span>
                </v-select>

                <vs-input
                  class="w-full"
                  v-model="data_local.contract_codes"
                  disabled
                  v-else
                />
              </div>

              <div class="mt-8">
                <vs-alert
                  color="warning"
                  icon-pack="feather"
                  icon="icon-info"
                  v-if="this.data_local.id"
                >
                  <span>{{ $t("user.messages.info.passwordChange") }}</span>
                </vs-alert>
                <form autocomplete="off">
                  <vs-input
                    class="w-full mt-4"
                    :label="$t('machine.password')"
                    v-model="password"
                    ref="password"
                    type="password"
                    name="password"
                    :data-vv-as="$t('login.password')"
                    autocomplete="new-password"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('password')"
                    >{{ errors.first("password") }}</span
                  >

                  <vs-input
                    class="w-full mt-4"
                    :label="$t('login.passwordConfirm')"
                    v-model="passwordConfirm"
                    ref="confirmation"
                    v-validate="password ? 'required|confirmed:password' : ''"
                    type="password"
                    name="passwordConfirmation"
                    :data-vv-as="$t('login.passwordConfirm')"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('passwordConfirmation')"
                    >{{ errors.first("passwordConfirmation") }}</span
                  >
                </form>
              </div>

            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-1/2 mt-6">
              
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  id="button-with-loading"
                  class="ml-auto mt-2 vs-con-loading__container"
                  @click="saveChanges"
                  :disabled="
                    !validateForm || $store.state.AppActiveUser.type === 0
                  "
                  >{{ $t("actions.save") }}</vs-button
                >
                <vs-button
                  class="ml-4 mt-2"
                  type="flat"
                  color="dark"
                  @click.native="$router.back()"
                  >{{ $t("actions.cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vx-card>
      </vs-col>

      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="4"
        v-if="data_local && data_local.id"
      >
        <vx-card
          id="sensors"
          class="vs-con-loading__container cardx"
          :title="$t('sensors.title')"
          refresh-content-action
          @refresh="loadSensors"
        >
          <vs-list>
            <div v-for="(sensor, index) in sensorStatus" :key="index">
              <vs-list-header
                v-if="sensor.description"
                :title="$t(`sensors.${sensor.category.toLowerCase()}`)"
              ></vs-list-header>
              <vs-list-item
                v-if="sensor.description"
                :title="sensor.code != '0' ? sensor.code : ''"
                :subtitle="sensor.date + ' : ' + sensor.description"
              ></vs-list-item>
            </div>
          </vs-list>
        </vx-card>

        <vx-card
          id="balance"
          class="vs-con-loading__balance cardx mt-5"
          :title="$t('transaction.balance')"
          refresh-content-action
          @refresh="loadBalance"
        >
          <div>
            <div v-for="(balance, index) in balanceData" :key="index">
              {{ $t('machine.vault.custodyAmount') }} {{ balance.currency }}: {{ balance.total }}

              <div v-for="(den, j) in balance.denominations" :key="j">
                <!--<span>{{ den.denomination }}</span>-->
                <p class="mt-3">{{ $t('machine.vault.denominations') }}</p>
                <div v-if="den.denomination < 2">
                  <div v-for="(entry, k) in den.entries" :key="k">
                    <div class="denominations">
                      <div>
                        <small
                          ><strong
                            ><span>{{ balance.currency }} {{ entry.value }}</span></strong
                          ></small
                        >
                      </div>
                      <div>
                        <small>: {{ entry.quantity }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <p>{{ $t("machine.vault.totalAutomatic") }}: {{ balance.automatic }}</p>
                <p>{{ $t("machine.vault.totalManual") }}: {{ balance.manual }}</p>
                <p>{{ $t("machine.vault.totalCheck") }}: {{ balance.check }}</p>
              </div>

            </div>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from 'vuejs-datepicker/src/locale';
import AddressComponent from '../components/AddressComponent.vue'

export default {
  components: {
    vSelect,
    Datepicker,
    AddressComponent
  },
  data: () => ({
    data_local: null,
    companyOptions: [],
    sensorsData: [],
    sensorStatus: [],
    password: null,
    passwordConfirm: null,
    languages: lang,
    modelOptions: [
      { label: "STRIKE", value: 0 },
      { label: "ONE", value: 1 },
      { label: "Catamoeda", value: 2 },
      { label: "FIT", value: 3 }
    ],
    balanceData: [],
    currencies: [
      "BRL",
      "USD",
      "AUD",
      "CLP",
      "EUR",
      "SOL",
      "PYG",
      "ARS",
      "PKR",
      "JOD",
      "KES",
      "DOP",
      "HNL",
      "AED"
    ],
    contractOptions: [],
    loadingContracts: true
  }),
  provide () {
    return { parentValidator: this.$validator }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    currentLanguage() {
      var current = localStorage.getItem('language');
      return (current == 'pt_BR') ? 'ptBR' : localStorage.getItem('language');
    },
  },
  beforeMount() {
    if (!this.$route.params.new && !this.$route.params.machine) {
      this.$router.push("/machine");
    }
  },
  mounted() {
    if (this.data_local && this.data_local.id) {
      this.loadSensors();
      this.loadBalance();
    }

    if (this.$store.state.AppActiveUser.type > 0) {
      this.loadCompanies();
    }

    if (this.$store.state.AppActiveUser.type === 2) {
      this.loadContracts();
    }  
  },
  created() {
    this.data_local = this.$route.params.machine;
  },
  methods: {
    addressChanged: function(address) {
			this.data_local = { ...this.data_local, ...address };
		},
    loadCompanies() {
      var self = this;

      this.$vs.loading({
        container: "#content",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then(data => {
          self.$vs.loading.close("#content > .con-vs-loading");
          self.companyOptions = data.filter(x => x.active);
        })
        .catch(error => {
          self.$vs.loading.close("#content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    loadContracts() {
      var self = this;
      self.loadingContracts = true;

      this.$store
        .dispatch("httpRequest", { method: "get", url: "contract/all" })
        .then(data => {
          self.contractOptions = data.filter(x => x.active == true);
          self.loadingContracts = false;
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    loadBalance(card) {
      var self = this;

      if (!card) {
        this.$vs.loading({
          container: "#balance",
          scale: 0.5
        });
      }

      this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `machine/${this.data_local.id}/vault`
        })
        .then(data => {
          self.$vs.loading.close("#balance  > .con-vs-loading");
          if (card) card.removeRefreshAnimation();
          const balances = data;

          self.balanceData = [];
          balances.forEach(balance => {
            const currencyCode = this.currencies[balance.currency - 1];
            const totalFormatted = balance.balance.toLocaleString("pt-BR", {
              style: "currency",
              currency: currencyCode,
              minimumFractionDigits: 2
            });

            self.balanceData.push({
              total: totalFormatted,
              automatic: this.formatCurrency(balance.automatic, currencyCode),
              manual: this.formatCurrency(balance.manual, currencyCode),
              check: this.formatCurrency(balance.check, currencyCode),
              currency: currencyCode,
              denominations: balance.denominations
            });
          });
        })
        .catch(error => {
          self.$vs.loading.close("#sensors  > .con-vs-loading");
          if (card) card.removeRefreshAnimation();
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },

    getCurrencyCode(currency) {
      return this.currencies[currency - 1];
    },

    formatCurrency(value, currencyCode) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 2
      });
    },

    loadSensors(card) {
      var self = this;

      if (!card) {
        this.$vs.loading({
          container: "#sensors",
          scale: 0.5
        });
      }

      this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `machine/${this.data_local.id}/events`
        })
        .then(data => {
          self.$vs.loading.close("#sensors  > .con-vs-loading");
          if (card) card.removeRefreshAnimation();

          self.sensorsData = data;
          self.sensorStatus = [];
          self.sensorsData.forEach(sensor => {
            const item = {
              category: sensor.category,
              code: sensor.code.toString(),
              description:
                sensor.description.replace("\\n", "") || sensor.details.TITLE,
              date: moment
                .utc(sensor.date)
                .local()
                .locale(localStorage.getItem("language"))
                .calendar()
            };
            self.sensorStatus.push(item);
          });
        })
        .catch(error => {
          self.$vs.loading.close("#sensors  > .con-vs-loading");
          if (card) card.removeRefreshAnimation();
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    saveChanges() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;

          this.$vs.loading({
            container: "#button-with-loading",
            scale: 0.5
          });

          var isNew = this.$route.params.new;

          if (this.password) {
            this.data_local.password = this.password;
          }

          delete this.data_local.last_ping;
          delete this.data_local.online;
          delete this.data_local.company;
          delete this.data_local.contracts_codes;
          delete this.data_local.nomeFantasia;
          delete this.data_local.catamoeda_status;

          var url = isNew ? "machine/create" : "machine/edit";
          var message = isNew
            ? i18n.t("machine.messages.success.create")
            : i18n.t("machine.messages.success.edit");

          this.$store
            .dispatch("httpRequest", {
              method: "post",
              url: url,
              data: this.data_local
            })
            .then(() => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$router.push("/machine");

              this.$vs.notify({
                title: i18n.t("dialog.success"),
                text: message,
                color: "success"
              });
            })
            .catch(error => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$vs.notify({
                title: i18n.t("dialog.error"),
                text: error.message,
                color: "danger"
              });
            });
        }
      });
    }
  }
};
</script>

<style>
.cardx {
  margin-left: 25px;
}

.vx-card__body {
  min-height: 80px;
}

.denominations {
  display: grid;
  grid-template-columns: 80px 100px;
}

.check-balance {
  margin-top: 15px;
}

h4 {
  color: #2c2c2c;
}
</style>
